import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

// const truncate = (input) =>
//   `${input.substring(0, 10)}...`;

  var truncate = function (fullStr, separator) {
    separator = separator || '...';
    return fullStr.substr(0, 6) + 
           separator + 
           fullStr.substr(38, 6);
           
};

export const StyledButton = styled.button`
  padding: 20px;
  border: 2px solid white;
  background-color: #2b5278;
  padding: 20px;
  font-weight: bold;
  font-size: 20px;
  color: var(--secondary-text);
  
  cursor: pointer;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 45px;
`;

export const StyledButtonConnect = styled.button`
  border: 2px none var(--secondary-text);
  background-color: #00000049;
  padding: 15px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 150px;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
`;

export const StyledButtonMedia = styled.button`
  border: 2px none var(--secondary-text);
  background-color: #00000049;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-top: 8px;
  padding-left: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
`;

export const StyledButtonMedia2 = styled.button`
  border: 2px none var(--secondary-text);
  background-color: #00000049;
  padding-right: 4px;
  padding-bottom: 2px;
  padding-top: 6px;
  padding-left: 4px;
  font-weight: bold;
  color: var(--secondary-text);
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
`;

export const StyledLogo = styled.img`
  width: 150px;
  @media (min-width: 767px) {
    width: 150px;
  }
  @media (min-width: 900px) {
    width: 200px;
  }
  @media (min-width: 1000px) {
    width: 250px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px none #505050;
  width: 100%;
  max-width: 350px;
`;

export const StyledImgDemo = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px none #ffffff2a;
  border-radius: 30px;
  width: 20%;
`;

export const StyledImgRoadmap = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px none #ffffff2a;
  border-radius: 30px;
  width: 100%;
`;

export const StyledImgTeam = styled.img`
  width: 15%;
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  
`;

export const StyledLinkMenu = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
  font-size: 20px;
`;


function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
 
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Preparing your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Error, please try again");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations ! You just minted a ${CONFIG.NFT_NAME}.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen >
      <s.ContainerMenu style={{ paddingTop: 24, paddingRight: 34, paddingLeft: 34 }}>
          <StyledButtonMedia>
          <a target={"_blank"} href="https://twitter.com/KillBotBase"><img src="/config/images/x-white.png" title="twitter logo" width="30" height="30" /></a> 
		  </StyledButtonMedia>
		  <StyledButtonMedia>
          <a target={"_blank"} href="https://t.me/KillBotBase"><img src="/config/images/tglogo.png" title="tg logo" width="30" height="30" /></a> 
		  </StyledButtonMedia>
		  <StyledButtonMedia2>
          <a target={"_blank"} href="https://dexscreener.com/base/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2"><img src="/config/images/dexscreener.png" title="dexscreener logo" width="40" height="40" /></a> 
		  </StyledButtonMedia2>
		  
          </s.ContainerMenu>
		  
		  <s.Container flex={0} ai={"center"} style={{ padding: 24}}>
        
			  <s.SpacerXLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/eyes.png"}/>
          </s.Container>
          
        </s.Container> 
		  <s.ContainerOutro>
          <s.TextTitle
            >
            
			<StyledLink  
              target={"_blank"} href={CONFIG.SCAN_LINK}>
               {CONFIG.CONTRACT_ADDRESS}
              </StyledLink>
          </s.TextTitle> 
		  
          </s.ContainerOutro>
		  <s.SpacerLargeplus />
		  <s.ContainerOutro>
		  <StyledButton>
		  <StyledLink  
              target={"_blank"} href="https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2">
                Buy on SushiSwap
              </StyledLink>
			  </StyledButton>
			  </s.ContainerOutro>
          
		  <s.SpacerXLarge />
          
		
		<s.Container flex={0} ai={"center"} style={{ padding: 24}}>
        <s.ContainerMint>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/killbotimg4.png"}/>
          </s.Container>
          <s.SpacerXLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>    
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				Initial Tokenomics :
				</StyledLink>
				</s.TextTitle>				
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				103,103,103 Max supply
				</StyledLink>
				</s.TextTitle>
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				97% liquidity
				</StyledLink>
				</s.TextTitle>
				 <s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				3% team tokens
				</StyledLink>
				</s.TextTitle>
                <s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				3% buy/sell tax
				</StyledLink>
				</s.TextTitle>				
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				_____________________________
				</StyledLink>
				</s.TextTitle>		
          </s.Container>
          </s.ContainerMint>
        <s.SpacerLargeplus />
        </s.Container>
		
		<s.Container flex={0} ai={"center"} style={{ padding: 24}}>
        <s.ContainerMint>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/killbotimg.png"}/>
          </s.Container>
          <s.SpacerXLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>    
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				Updated Tokenomics :
				</StyledLink>
				</s.TextTitle>				
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				40.5% burned
				</StyledLink>
				</s.TextTitle>
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				45% liquidity
				</StyledLink>
				</s.TextTitle>
				 <s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				0% team tokens
				</StyledLink>
				</s.TextTitle>
                <s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				3% buy/sell tax
				</StyledLink>
				</s.TextTitle>				
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				_____________________________
				</StyledLink>
				</s.TextTitle>				
          </s.Container>
          </s.ContainerMint>
        <s.SpacerLargeplus />
        </s.Container>
		
		<s.Container flex={0} ai={"center"} style={{ padding: 24}}>
        <s.ContainerMint>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/killbotimg3.png"}/>
          </s.Container>
          <s.SpacerXLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>    
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/tx/0x0f3b9c758920b54e1454823d4cb431752813fbc0e1d6dae1aee4834492804ff5"
				>
				We burned
				</StyledLink>
				</s.TextTitle>	
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/tx/0x0f3b9c758920b54e1454823d4cb431752813fbc0e1d6dae1aee4834492804ff5"
				>
				37.5% of the supply !
				</StyledLink>
				</s.TextTitle>					
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/tx/0x0f3b9c758920b54e1454823d4cb431752813fbc0e1d6dae1aee4834492804ff5"
				>
				Check the TX here !
				</StyledLink>
				</s.TextTitle>
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				_____________________________
				</StyledLink>
				</s.TextTitle>				
          </s.Container>
          </s.ContainerMint>
        <s.SpacerLargeplus />
        </s.Container>  
		
		<s.Container flex={0} ai={"center"} style={{ padding: 24}}>
        <s.ContainerMint>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/killbotimg2.png"}/>
          </s.Container>
          <s.SpacerXLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>    
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/tx/0xc66eee4bae9e292708de9975dc7805fe45088247d5f282e53a1c4b0ce9a500ee"
				>
				We burned
				</StyledLink>
				</s.TextTitle>	
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/tx/0xc66eee4bae9e292708de9975dc7805fe45088247d5f282e53a1c4b0ce9a500ee"
				>
				 ALL Team tokens !
				</StyledLink>
				</s.TextTitle>					
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/tx/0xc66eee4bae9e292708de9975dc7805fe45088247d5f282e53a1c4b0ce9a500ee"
				>
				Check the TX here !
				</StyledLink>
				</s.TextTitle>
				<s.TextTitle style={{lineHeight: "200%"}}>
				<StyledLink  
				target={"_blank"} href="https://basescan.org/token/0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2#code"
				>
				_____________________________
				</StyledLink>
				</s.TextTitle>
          </s.Container>
          </s.ContainerMint>
        <s.SpacerLargeplus />
        </s.Container>  
		
		
		
		<s.ContainerOutro>
		<StyledButton>
              <StyledLink 
              target={"_blank"} href="https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x8806761621e628eD9E1C0f8F52f5E26fAe69f9B2">
                Buy on SushiSwap
              </StyledLink>
			  </StyledButton>
        </s.ContainerOutro>
		<s.SpacerMedium/>
      <s.ContainerOutro style={{ padding: 24}}>
          <s.TextDescription
            style={{ textAlign: "center", color: "var(--primary-text)", padding: 12, boxShadow: "0px 5px 11px 2px rgba(0, 0, 0, 0.7)", backgroundColor: "#00000049"}}
          >
            We based on {CONFIG.NETWORK.NAME}. 
          </s.TextDescription> 
        </s.ContainerOutro>
        <s.SpacerMedium/>
        
		
    </s.Screen>
	
  );
}

export default App;
